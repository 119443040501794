/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);

		/*
		Header
		*/
		specific_header.onready();

		/*
		Ontdek Trevo
		*/
		if(
			url[2] == 'ontdek-trevo' ||
			url[2] == 'discover-trevo' ||
			url[2] == 'ik-zoek-werk' ||
			url[2] == 'im-looking-for-a-job' ||
			url[2] == 'ik-heb-werk' ||
			url[2] == 'im-looking-for-people'
		) {
		
			/*
			Time
			*/
			specific_time.onready();

		}

		/*
		Trigger: classes
		*/

		/*
		Job: Scroll to Contact
		*/
		if(url[7] == 'contact') {
				
			/*
			Marge
			*/
			var _marge;
			if($(window).width() > 600) {
				_marge = 100;
			} else {
				_marge = 50;
			}
			$('html, body').animate({
			    scrollTop: $(".js-areyouthis").offset().top - ($('header').height() + _marge)
			}, 500);

		}


		/*
		Swiper: Init
		*/
		// var swiper = new Swiper(".js-team-swiper", {
	    //     pagination: {
	    //       el: ".swiper-pagination",
	    //     }
	    // });

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
