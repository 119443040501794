/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_videoplayer = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		$(document).on('click','.js-videoplayer-trevo',function() {
			specific_videoplayer.toggle();
		});

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.toggle = function() {

		if(_trevo_video_isplaying == 0) {

			var _video_file = $('.js-videoplayer-loader').data('file');

			var _video_str;
			_video_str  = '<video controls autoplay>';
			_video_str += '<source src="' + _video_file + '" type="video/mp4">';
			_video_str += '</video>';

			$('.js-videoplayer-poster').fadeOut();
			$('.js-videoplayer-loader').html(
				_video_str
			);

			/*
			Video: Ended?
			*/
			var video = document.getElementsByTagName('video')[0];

			video.onended = function(e) {
				specific_videoplayer.toggle();
			};

			_trevo_video_isplaying = 1;

		} else {

			$('.js-videoplayer-poster').fadeIn();
			$('.js-videoplayer-loader').html('');

			_trevo_video_isplaying = 0;
		}
	};
			
}).apply(specific_videoplayer); 