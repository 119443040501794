/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_header = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		/*
		Check URL
		*/
		if(
			url[1] == '' ||
			url[2] == '' ||
			url[2] == 'ik-heb-werk' && url[3] == '' ||
			// url[2] == 'im-looking-for-a-job' && url[3] == '' ||
			url[2] == 'ik-zoek-werk' && url[3] == '' ||
			// url[2] == 'i-have-a-vacancy' && url[3] == '' ||
			url[2] == 'ontdek-trevo' && url[3] == ''
			// url[2] == 'discover-trevo' && url[3] == ''

		) {

			specific_header.toggle(
				1,
				url[2],
				url[3]
			);

		} else {
			
			specific_header.toggle(
				2,
				url[2],
				url[3]
			);

		}

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.toggle = function(
		_header_type,
		_header_category,
		_header_branch
	) {

		/*
		Page: Ontdek Trevo
		Page: Ontdek Trevo
		Page: Ontdek Trevo
		Page: Ontdek Trevo
		*/
		if(
			_header_category == 'ontdek-trevo'
		) {

			$('.js-header-1').show();

			$('.js-cta').hide();
			$('.js-overlay-goback').show();

			$('.js-header-2').html('');
			$('.js-header-2').hide();

			$('.js-mobile-menu').html('');
			
			$('footer').hide();

		/*
		Other pages
		Other pages
		Other pages
		Other pages
		*/
		} else {

			$('.js-cta').show();
			$('.js-overlay-goback').hide();

			/*
			Load footer
			Load footer
			Load footer
			Load footer
			*/
			$.ajax({
				url: globalUrl + '/' + url[1] + '/a/l/footer',
				type: "POST",
				data: {
					category		: _header_category,
					branch			: _header_branch
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				$('.js-footer-loader').html(data);

			});

			/*
			Load mobilemenu
			Load mobilemenu
			Load mobilemenu
			Load mobilemenu
			*/
			$.ajax({
				url: globalUrl + '/' + url[1] + '/a/l/mobilemenu',
				type: "POST",
				data: {
					category		: _header_category,
					branch			: _header_branch
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				$('.js-mobile-menu').html(data);

				/*
				Time
				*/
				specific_time.onready();

				/*
				Catch and active URL
				*/
				var _url_active_main = './';
				
				$('.js-nav-item').removeClass('active');
				
				if(url[4] !== '') { 
					_url_active_main = url[4]; 
				} else {
					// if(url[2] !== '') { 
					// 	_url_active_main = url[2];
					// }
				}

				if(_url_active_main !== '') {
					$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
				}


			});

			/*
			Load header
			Load header
			Load header
			Load header
			*/
			$.ajax({
				url: globalUrl + '/' + url[1] + '/a/l/header',
				type: "POST",
				data: {
					header_category			: _header_category,
					header_branch			: _header_branch
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				/*
				Set: HTML
				*/
				$('.js-header-2').html(data);

				/*
				Switch on and off
				*/
				switch(_header_type) {

					case 1:

						$('.js-header-1').hide();
						$('.js-header-2').show();

						$('footer').show();

					break;

					case 2:

						$('.js-header-1').hide();
						$('.js-header-2').show();

						$('footer').show();

					break;

				}

				/*
				Time
				*/
				specific_time.onready();

				/*
				Catch and active URL
				*/
				var _url_active_main = './';
				
				$('.js-nav-item').removeClass('active');
				
				if(url[4] !== '') { 
					_url_active_main = url[4]; 
				} else {
					// if(url[2] !== '') { 
					// 	_url_active_main = url[2];
					// }
				}

				if(_url_active_main !== '') {
					$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
				}

				/*
				Header / Hero
				*/
				$('.js-hero').css({
					'margin-top': $('.js-header-2').height()
				});

			})
			.always(function() {
			});

		}

	};
			
}).apply(specific_header); 