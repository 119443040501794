/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_jobsearch = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		/*
		Category
		*/
		$(document).on('click','.js-jobs-category',function() {

			$('.js-jobs-category').removeClass('active');
			$(this).addClass('active');

			var _category = $(this).data('category');

			$.ajax({
				url: globalUrl + '/' + url[1] + '/a/l/vacatures',
				type: "POST",
				data: {
					category		: _category
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				$('.js-jobs-loader').html(data);

				if($('.js-job-item').length >= 1) {
					$('.js-jobs-filter').fadeIn();
				} else {
					$('.js-jobs-filter').hide();
				}

				$('.js-jobs-loader').fadeIn();

				/*
				Show title
				*/
				$('.js-jobs-title').hide();
				if(
					$('.js-job-item:visible').length >= 1
				) {
					$('.js-jobs-title[data-cat="gevonden"]').show();
				} else {
					$('.js-jobs-title[data-cat="niet-gevonden"]').show();
				}

			});

		});

		/*
		Filtering
		*/
		$(document).on('change','.js-jobs-filter',function() {

			specific_jobsearch.filter();

		});

		/*
		Jobs: All
		*/
		// $(document).on('click','.js-jobs-showall',function() {

		// 	$('.js-job-item').show();
		// 	$('.js-job-item').removeClass('opacity-60 opacity-40');

		// });

		/*
		Jobs: Autocomplete
		*/
		// $(document).on('input','.js-filter-search',function() {

		// 	var _title 	= $('.js-filter-search').val().toLowerCase();
		// 	var _city 	= $('.job-search-city').val().toLowerCase();

		// 	specific_jobsearch.search(
		// 		_title,
		// 		_city

		// 	);

		// });

		/*
		Jobs: Autocomplete
		*/
		// $(document).on('change','.job-search-city',function() {

		// 	var _title 	= $('.js-filter-search').val().toLowerCase();
		// 	var _city 	= $('.job-search-city').val().toLowerCase();

		// 	specific_jobsearch.search(
		// 		_title,
		// 		_city

		// 	);

		// });


	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.filter = function() {

		var selectedBemiddeling  	= $('.js-jobs-filter[data-filter="bemiddeling"]').val();
		var selectedLocatie 		= $('.js-jobs-filter[data-filter="locatie"]').val();
		var selectedDienstverband  	= $('.js-jobs-filter[data-filter="dienstverband"]').val();

		$(".js-job-item").each(function() {

			var bemiddeling 		= $(this).data("bemiddeling");
		    var locatie 			= $(this).data("provincie");
		    var dienstverband 		= $(this).data("dienstverband");

			var isVisible 			= (selectedBemiddeling === "alle" || bemiddeling === selectedBemiddeling) &&
				                      (selectedLocatie === "alle" || locatie === selectedLocatie) &&
				                      (selectedDienstverband === "alle" || dienstverband === selectedDienstverband);

            if (isVisible) {
		      $(this).show();
		    } else {
		      $(this).hide();
		    }

		});

		/*
		Show title
		*/
		$('.js-jobs-title').hide();
		if(
			$('.js-job-item:visible').length >= 1
		) {
			$('.js-jobs-title[data-cat="gevonden"]').show();
		} else {
			$('.js-jobs-title[data-cat="niet-gevonden"]').show();
		}

	};

	// this.search = function(
	// 	_title,
	// 	_city
	// ) {

	// 	$('.js-job-item').hide();

	// 	/*
	// 	Show All
	// 	*/
	// 	if(
	// 		_title == '' && 
	// 		_city == 'all'
	// 	) {

	// 		$('.js-job-item').show();

	// 	} else {

	// 		/*
	// 		Select only when title is containing the search
	// 		*/
	// 		clearTimeout(typingTimer);
	// 			typingTimer = setTimeout(function() {

	// 			$('.js-job-item').map(function() {

	// 				if(_city == 'all') {

	// 					if(
	// 						$(this).attr('data-title').includes(_title) == true
	// 					) {
	// 						$(this).show();
	// 					}

	// 				} else {

	// 					if(_title == '') {

	// 						if(
	// 							$(this).attr('data-city') == _city
	// 						) {
	// 							$(this).show();
	// 						}

	// 					} else {

	// 						if(
	// 							$(this).attr('data-title').includes(_title) == true &&
	// 							$(this).attr('data-city') == _city
	// 						) {
	// 							$(this).show();
	// 						}

	// 					}

	// 				}

	// 			});

	// 			//$('.js-job-item[data-title*="' + _title + '"]').show();

	// 		},300);

	// 	}

	// };
			
}).apply(specific_jobsearch); 