/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2023 
	27-03-2023
	- [CHANGE] The mobile Fix: 100% Height mobile was not good enough, it's better now.
2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			/*
			Fix: 100% Height mobile
			*/
			document.querySelector(':root').style.setProperty('--vh', window.innerHeight + 'px');

			/*
			Header / Hero
			*/
			$('.js-hero').css({
				'margin-top': $('.js-header-2').height()
			});
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {

		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};
		
}).apply(specific_interactions_window); 