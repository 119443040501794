/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_time = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		var _timer_is_here = $('.js-time').length;

		/*
		Reset timer
		*/
		_timer = 0;

		/*
		Timer there?
		*/
		currentTime = specific_time.getTime();
		$('.js-time').html(currentTime);

		/*
		Repeat
		*/
		if(_timer_is_here >= 1) {
			
			_timer = setInterval(function(){
		        currentTime = specific_time.getTime();
		       $('.js-time').html(currentTime);
		    }, 1000);

		}

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.getTime = function() {

	    var now     = new Date(); 
        var year    = now.getFullYear();
        var month   = now.getMonth()+1; 
        var day     = now.getDate();
        var hour    = now.getHours();
        var minute  = now.getMinutes();
        var second  = now.getSeconds(); 
        if(month.toString().length == 1) {
             month = '0'+month;
        }
        if(day.toString().length == 1) {
             day = '0'+day;
        }   
        if(hour.toString().length == 1) {
             hour = '0'+hour;
        }
        if(minute.toString().length == 1) {
             minute = '0'+minute;
        }
        if(second.toString().length == 1) {
             second = '0'+second;
        }   
        var dateTime = hour + ' : ' + minute + ' : '+ second;  
        
        return dateTime;

	};
			
}).apply(specific_time); 