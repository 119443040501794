/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_feedback = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		$(document).on('click','.js-click-feedback-bt',function() {

			var _vacature_title 	= $("title").text();
			var _feedback 			= $(this).data('feedback');

			specific_feedback.send(
				_vacature_title,
				_feedback
			);

		});

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.send = function(
		_vacature_title,
		_feedback
	) {

		/*
		Popup: Feedback
		*/
		popup.open(1,1,'feedback');

	  	/*
	  	Ajax
	  	*/
	  	$.ajax({
			url: globalUrl + '/' + url[1] + '/a/a/feedback',
			type: "POST",
			data: {
				title		: _vacature_title,
				feedback		: _feedback
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			$('.js-feedback-proces').hide();
			$('.js-feedback-done').show();

		});


	};
			
}).apply(specific_feedback); 